import React from 'react';

interface NoLaunchDarklyPageProps {
  error: Error;
}

export default function NoLaunchDarklyPage({ error }: NoLaunchDarklyPageProps) {
  return (
    <>
      <h1>Error</h1>
      <p>
        <strong>Error:</strong> {error.message}
      </p>
    </>
  );
}
