import React from 'react';
import 'stylesheets/modal.css';

interface ModalProps {
  children: any;
}

const Modal = ({ children }: ModalProps) => {
  return (
    <div className="modal">
      <div className="content">{children}</div>
      <div className="background" />
    </div>
  );
};

export default Modal;
