import { standardHeaders } from './standardHeaders';
import processJsonResponse from './processJsonResponse';
import handleJsonErrors from './handleJsonErrors';

export const fetchLdClient = () => {
  return fetch('/wmb/api/ldclient.php', {
    method: 'GET',
    headers: standardHeaders(),
  })
    .then(processJsonResponse)
    .then(handleJsonErrors);
};
