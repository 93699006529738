import React, { useState } from 'react';
import MapTooltip from './MapTooltip';
import UsaMap from './UsaMap';
import { StateMetadata } from 'interfaces/StateMetadata';

interface MapContainerProps {
  stateMetadata: { [stateName: string]: StateMetadata };
  isTouchDevice: boolean;
}

const MapContainer = ({ stateMetadata, isTouchDevice }: MapContainerProps) => {
  const [selectedState, setSelectedState] = useState<StateMetadata | null>(
    null,
  );
  const [hoverState, setHoverState] = useState<string | null>(null);
  const [coordinates, setCoordinates] = useState<{
    x: number;
    top: number;
  }>({ x: 0, top: 0 });
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

  const handleTouchOrMouseEnter = (element: string, e?: any) => {
    if (Object.keys(stateMetadata).includes(element)) {
      if (element !== hoverState) {
        setHoverState(element);
        setSelectedState(stateMetadata[element]);
        setTooltipVisible(true);

        if (e.type === 'touchstart') {
          e.stopPropagation();

          let xCoordinate;
          let yCoordinate;

          if (window.outerWidth - e.touches[0].screenX < 220) {
            xCoordinate = e.touches[0].screenX - 200;
          } else {
            xCoordinate = e.touches[0].screenX - 10;
          }

          if (element === 'florida' || element === 'texas') {
            yCoordinate = e.touches[0].screenY - 200;
          } else if (element === 'alaska' || element === 'hawaii') {
            yCoordinate = e.touches[0].screenY - 50;
          } else if (window.outerHeight - e.touches[0].screenY <= 120) {
            yCoordinate = e.touches[0].screenY - 100;
          } else {
            yCoordinate = e.touches[0].screenY - 10;
          }

          setCoordinates({ x: xCoordinate, top: yCoordinate });
        } else if (e.type === 'mouseenter') {
          let xCoordinate;
          let yCoordinate;

          if (window.innerWidth - e.pageX < 300) {
            xCoordinate = e.pageX - 250;
          } else {
            xCoordinate = e.pageX - 10;
          }

          if (element === 'florida' || element === 'texas') {
            yCoordinate = e.pageY - 200;
          } else if (element === 'alaska' || element === 'hawaii') {
            yCoordinate = e.pageY - 50;
          } else if (window.innerHeight - e.pageY < 120) {
            yCoordinate = e.pageY - 100;
          } else if (window.innerHeight - e.pageY < 100) {
            yCoordinate = e.pageY - 50;
          } else {
            yCoordinate = e.pageY;
          }

          setCoordinates({ x: xCoordinate, top: yCoordinate });
        }
      }
    } else if (element !== 'tooltip') {
      setSelectedState(null);
      setHoverState(null);
      setTooltipVisible(false);
    }
  };

  const handleMouseLeave = () => {
    setHoverState(null);
    setSelectedState(null);
    setTooltipVisible(false);
  };

  return (
    <>
      {isTouchDevice ? (
        <MapTooltip
          coordinates={coordinates}
          tooltipVisible={tooltipVisible}
          selectedState={selectedState}
          handleTouchOrMouseEnter={handleTouchOrMouseEnter}
        />
      ) : null}
      <UsaMap
        stateMetadata={stateMetadata}
        handleTouchOrMouseEnter={handleTouchOrMouseEnter}
        handleMouseLeave={handleMouseLeave}
        hoverState={hoverState}
      >
        {!isTouchDevice ? (
          <MapTooltip
            coordinates={coordinates}
            tooltipVisible={tooltipVisible}
            selectedState={selectedState}
            handleTouchOrMouseEnter={handleTouchOrMouseEnter}
          />
        ) : null}
      </UsaMap>
    </>
  );
};

export default MapContainer;
