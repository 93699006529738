import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import ReactGA from 'react-ga4';
import 'App.css';
import Footer from 'components/Footer';
import Header from 'components/Header';
import MapContainer from 'components/map/MapContainer';
import MapKey from 'components/map/MapKey';
import Modal from 'components/Modal';
import PrivacyPage from 'components/PrivacyPage';
import Loading from 'components/Loading';
import { getStateMetadata } from 'api/stateMetadataApi';
import CountySearch from 'components/CountySearch';

function App() {
  const [showPrivacy, setShowPrivacy] = useState<boolean>(false);
  const [isTouchDevice, setIsTouchDevice] = useState<boolean>(false);

  const { data, isLoading, error } = useQuery(
    'state-metadata',
    getStateMetadata,
    {
      staleTime: 120 * 60 * 1000,
      cacheTime: 120 * 60 * 1000,
    },
  );

  useEffect(() => {
    const touchDevice = () => {
      return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    };
    setIsTouchDevice(touchDevice());
  }, [setIsTouchDevice]);

  useEffect(() => {
    ReactGA.send('pageview');
  }, []);

  return (
    <div className="container">
      <Header />

      <main className="main">
        <h2 className="h2Wmb">Where's My Ballot?</h2>
        <p className="subHead" id={'searchFieldLabel'}>
          Check to see if BallotTrax is available in your area
        </p>
        {isLoading && <Loading />}
        {error && !isLoading && <div>Network response error</div>}
        {data && !error && (
          <>
            <CountySearch counties={data.counties} />
            <div className="mapContainer">
              <p className="mapInstructions">
                {isTouchDevice
                  ? 'Tap on your state:'
                  : 'Hover over your state:'}
              </p>
              <MapContainer
                stateMetadata={data.states}
                isTouchDevice={isTouchDevice}
              />
              <MapKey />
            </div>
          </>
        )}
        {showPrivacy && (
          <Modal>
            <PrivacyPage setShowPrivacy={setShowPrivacy} />
          </Modal>
        )}
      </main>
      <Footer setShowPrivacy={setShowPrivacy} />
    </div>
  );
}

export default App;
