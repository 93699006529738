import React, { useEffect, useRef } from 'react';
import Button from './Button';
import 'stylesheets/privacyPage.css';

interface PrivacyPageProps {
  setShowPrivacy: (show: boolean) => void;
}

const PrivacyPage = ({ setShowPrivacy }: PrivacyPageProps) => {
  const formRef = useRef<any>(null);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.focus();
    }
  }, [formRef]);

  return (
    <div ref={formRef} tabIndex={-1}>
      <div className="modalTitle">Our Commitment to Your Privacy</div>
      <div className="privacyDisclosure">
        <p>
          BallotTrax fully respects its users right to privacy. We are just as
          concerned about your privacy and the use of your personal information
          as you are. We assure you that any and all information you provide us
          with will remain confidential. BallotTrax is dedicated to protecting
          your privacy and ensuring confidence in our secure ballot locator and
          notification system.
        </p>
        <p>
          By signing up with BallotTrax, you agree to opt-in to receive
          automatic mail ballot locating and notification services and you give
          your county permission to use your contact information to communicate
          election related information to you.
        </p>
        <p>
          Your personal information will not be sold or in any way divulged to
          any 3rd parties. You may opt-out at any time.
        </p>
        <Button
          className="returnButton"
          label={'return'}
          handleClick={() => setShowPrivacy(false)}
        />
      </div>
    </div>
  );
};

export default PrivacyPage;
