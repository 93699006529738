export class Unauthorized extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Unauthorized';
  }
}

export class MFARequired extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MFARequired';
  }
}

export class Forbidden extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Forbidden';
  }
}

export class NotFound extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NotFound';
  }
}

export class InternalServerError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InternalServerError';
  }
}

export class BadRequest extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'BadRequest';
  }
}

export class UnprocessableEntity extends Error {
  private readonly validation_message: object;

  constructor(message: string, validation_message: object) {
    super(message);
    this.name = 'UnprocessableEntity';
    this.validation_message = validation_message;
  }
  getErrorObject() {
    return this.validation_message;
  }
}

export class GenericApiError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'GenericApiError';
  }
}

export class Conflict extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Conflict';
  }
}
