import React from 'react';
import 'index.css';
import App from 'App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga4';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { fetchLdClient } from 'api/ldClient';
import NoLaunchDarklyPage from 'components/NoLaunchDarklyPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createRoot } from 'react-dom/client';

(async () => {
  const clientID = await fetchLdClient()
    .then((res) => res.client_id)
    .catch((err) => {
      createRoot(document.getElementById('root') as Element).render(
        <NoLaunchDarklyPage error={err} />,
      );
    });

  const user = {
    anonymous: true,
    key: 'anonymous-pre-login-wmb-user',
  };

  const LDProvider = await asyncWithLDProvider({
    clientSideID: clientID,
    user,
  });

  const queryClient = new QueryClient();

  ReactGA.initialize('UA-55273806-2', {
    testMode: false,
  });

  createRoot(document.getElementById('root') as Element).render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <LDProvider>
          <App />
          <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
        </LDProvider>
      </QueryClientProvider>
    </React.StrictMode>,
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
})();
