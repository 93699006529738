import React from 'react';
import starIcon from '../img/star-yellow.png';
import 'stylesheets/sectionDivider.css';

interface SectionDividerProps {
  className?: string;
}

const SectionDivider = ({ className }: SectionDividerProps) => {
  return (
    <div className={`dividerContainer ${className ? className : ''}`}>
      <div className="sectionDivider">
        <div>
          <img src={starIcon} alt="" className="starIcon" />
        </div>
        <div>
          <img src={starIcon} alt="" className="starIcon" />
        </div>
        <div>
          <img src={starIcon} alt="" className="starIcon" />
        </div>
        <div>
          <img src={starIcon} alt="" className="starIcon" />
        </div>
        <div>
          <img src={starIcon} alt="" className="starIcon" />
        </div>
      </div>
    </div>
  );
};

export default SectionDivider;
