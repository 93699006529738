import React from 'react';
import ballottrax_logo from '../img/ballottrax_logo.png';
import 'stylesheets/header.css';

const Header = () => {
  return (
    <header className="header">
      <h1 className="h1ImageOnly">
        <img
          src={ballottrax_logo}
          alt={'BallotTrax'}
          className="ballotTraxLogo"
        />
      </h1>
    </header>
  );
};

export default Header;
