import React from 'react';
import ReactGA from 'react-ga4';
import 'stylesheets/footer.css';

interface FooterProps {
  setShowPrivacy: (showModal: boolean) => void;
}

const Footer = ({ setShowPrivacy }: FooterProps) => {
  return (
    <footer className="footer">
      <div className="copyright">
        © {new Date().getFullYear()} by i3logix. All rights reserved
      </div>
      <div
        className="privacyLink"
        onClick={() => {
          ReactGA.event({
            label: 'modal',
            nonInteraction: false,
            transport: 'beacon',
            value: 1,
            category: 'modal view',
            action: 'privacy',
          });
          setShowPrivacy(true);
        }}
      >
        Your Privacy and Security
      </div>
    </footer>
  );
};

export default Footer;
