import React, { ChangeEvent, useState } from 'react';
import 'stylesheets/countySearch.css';
import { CountyOption } from 'interfaces/StateMetadata';
import debounce from 'util/debounce';
import SectionDivider from './SectionDivider';
import ReactGA from 'react-ga4';

interface CountySearchProps {
  counties: CountyOption[];
}

const handleGAEvent = (countyUrl: string) => {
  ReactGA.event({
    category: 'hyperlink',
    action: 'click - county search hyperlink',
    label: countyUrl,
  });
};

const CountySearch = ({ counties }: CountySearchProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [filteredCounties, setFilteredCounties] = useState<CountyOption[]>([]);
  const [notAvailableMessage, setNotAvailableMessage] = useState<string | null>(
    null,
  );

  const filterCounties = (value: string) => {
    const trimmedValue = value.trim();
    if (trimmedValue.length === 0) {
      return [];
    }
    return counties.filter((county: CountyOption) => {
      return county.county_state
        .toLowerCase()
        .includes(trimmedValue.toLowerCase());
    });
  };

  const getCounties = () => {
    return filteredCounties.map((county) => {
      const url = county.url || 'https://ballottrax.net/voter/';
      return (
        <li key={county.county_state} className={'listItem'}>
          <a
            href={url}
            target={'_blank'}
            rel={'noopener noreferrer'}
            className={'countySearchLink'}
            onClick={() => handleGAEvent(url)}
          >
            {county.county_state}
          </a>
        </li>
      );
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
    const counties = filterCounties(value);
    setFilteredCounties(counties);
    setNotAvailableMessage(null);
    debounce(() => {
      if (counties.length === 0 && value.trim().length > 0) {
        setNotAvailableMessage(
          `Unfortunately, BallotTrax is not available in "${value}" at this time.`,
        );
        ReactGA.event({
          category: 'search',
          action: 'search - no result',
          label: value,
        });
      }
    }, 800);
  };

  const getColumns = () => {
    if (filteredCounties.length > 2) {
      return 'col3';
    }
    if (filteredCounties.length === 2) {
      return 'col2';
    }
    return '';
  };

  return (
    <>
      <SectionDivider className="topDivider" />
      <form className={'countySearchForm'} onSubmit={(e) => e.preventDefault()}>
        <input
          name={'county'}
          className={'countySearch'}
          type={'search'}
          value={inputValue}
          onChange={handleChange}
          placeholder={'County Name, State'}
          autoComplete={'off'}
          aria-labelledby={'searchFieldLabel'}
        />
        {filteredCounties.length > 0 && (
          <>
            <div>BallotTrax is available in:</div>
            <div className={'resultsContainer'}>
              <ul className={`results ${getColumns()}`}>{getCounties()}</ul>
            </div>
          </>
        )}
        {filteredCounties.length === 0 && notAvailableMessage && (
          <div>
            <p>{notAvailableMessage}</p>
          </div>
        )}
      </form>
      {!inputValue && <SectionDivider className="bottomDivider" />}
    </>
  );
};

export default CountySearch;
