import React from 'react';
import 'stylesheets/mapKey.css';

interface CircleProps {
  color: string;
}

const Circle = ({ color }: CircleProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <circle cx="12" cy="12" r="7" fill={color} />
        </g>
      </g>
      <title>{`Map ${
        color === '#267e4b' ? 'dark green' : 'light green'
      }  color legend`}</title>
    </svg>
  );
};

const MapKey = () => {
  return (
    <div className="keyContainer">
      <div className="keyInnerContainer">
        <div className="key">
          <Circle color={'#267e4b'} />
          <span>States using BallotTrax</span>
        </div>
        <div className="key">
          <Circle color={'#75bc93'} />
          <span>States with BallotTrax Counties</span>
        </div>
        <div className="key">
          <Circle color={'#bdbdbd'} />
          <span>BallotTrax not supported</span>
        </div>
      </div>
    </div>
  );
};

export default MapKey;
