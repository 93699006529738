import {
  BadRequest,
  Conflict,
  Forbidden,
  GenericApiError,
  InternalServerError,
  MFARequired,
  NotFound,
  Unauthorized,
  UnprocessableEntity,
} from './ApiErrors';

const handleJsonErrors = ({
  body,
  isError,
}: {
  body: any;
  isError: boolean;
}) => {
  if (!isError) {
    return body;
  }

  const errorMessage = body.title
    ? `${body.status}: ${body.title} - ${body.detail}`
    : `${body.status}: ${body.detail}`;

  switch (body.status) {
    case 400:
      throw new BadRequest(errorMessage);
    case 401:
      if (body.code === 'MFA_REQUIRED') {
        throw new MFARequired(errorMessage);
      }
      throw new Unauthorized(errorMessage);
    case 403:
      throw new Forbidden(errorMessage);
    case 404:
      throw new NotFound(errorMessage);
    case 409:
      throw new Conflict(errorMessage);
    case 422:
      throw new UnprocessableEntity(body.status, {
        ...body.validation_messages,
      });
    case 500:
      throw new InternalServerError(errorMessage);
    default:
      throw new GenericApiError(`Application Error: ${errorMessage}`);
  }
};

export default handleJsonErrors;
