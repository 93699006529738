import React from 'react';
import 'stylesheets/button.css';

interface ButtonProps {
  label: string;
  handleClick: () => void;
  className?: string;
}

function Button({ label, handleClick, className }: ButtonProps) {
  return (
    <div className="buttonContainer">
      <button
        type="button"
        className={className ? className : ''}
        onClick={handleClick}
      >
        {label}
      </button>
    </div>
  );
}

export default Button;
