import { standardHeaders } from './standardHeaders';
import {
  CountyOption,
  FormattedMetadata,
  StateMetadata,
} from '../interfaces/StateMetadata';
import replaceUrlDomainWithBrowserDomain from './replaceUrlDomainWithBrowserDomain';

const getCounties = (state: StateMetadata) => {
  return state.counties.map((county) => {
    return {
      county_state: county.county_name + ', ' + state.state,
      url: county.county_url || state.url || 'https://ballottrax.net/voter/',
    };
  });
};

export const getStateMetadata = (): Promise<FormattedMetadata> => {
  const url = replaceUrlDomainWithBrowserDomain('/api/state-metadata');
  return fetch(url, {
    method: 'GET',
    headers: standardHeaders(),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      return res.json();
    })
    .then((data) => {
      let statesData: { [stateName: string]: StateMetadata } = {};
      let counties: CountyOption[] = [];

      data._embedded.state_metadata.forEach((state: StateMetadata) => {
        const stateName = state.state.toLowerCase().replace(' ', '_');
        statesData[stateName] = state;
        counties.push(...getCounties(state));
      });

      return { states: statesData, counties };
    });
};
