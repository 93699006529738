import React from 'react';
import 'stylesheets/mapTooltip.css';
import { CountyLink, StateMetadata } from 'interfaces/StateMetadata';
import ReactGA from 'react-ga4';

interface MapTooltipProps {
  coordinates: { x: number; top: number };
  tooltipVisible: boolean;
  selectedState: StateMetadata | null;
  handleTouchOrMouseEnter: (tooltip: string) => void;
}

const handleGAEvent = (countyUrl: string) => {
  ReactGA.event({
    category: 'hyperlink',
    action: 'click - map hyperlink',
    label: countyUrl,
  });
};

const getColumnNumber = (countiesArray: CountyLink[] | null) => {
  if (!countiesArray) {
    return 0;
  }
  if (countiesArray.length > 39) {
    return 3;
  }
  if (countiesArray.length > 19) {
    return 2;
  }
  return 1;
};

const getCountyList = (countiesArray: CountyLink[] | null) => {
  if (!countiesArray || countiesArray.length === 0) {
    return <li>No Participating Counties</li>;
  }
  return countiesArray.map((county) => {
    const url = county.county_url || 'https://ballottrax.net/voter/';
    return (
      <li key={county.county_name}>
        <a
          href={url}
          target={'_blank'}
          rel={'noopener noreferrer'}
          onClick={() => handleGAEvent(url)}
        >
          {county.county_name}
        </a>
      </li>
    );
  });
};

const MapTooltip = ({
  coordinates,
  tooltipVisible,
  selectedState,
  handleTouchOrMouseEnter,
}: MapTooltipProps) => {
  if (selectedState) {
    let stateName;
    if (selectedState.state === 'DC') {
      stateName = 'Washington DC';
    } else {
      stateName = selectedState.state;
    }

    //  "|| 0" added to the left and top css styles were added to prevent the
    // react-testing library tests from complaining that css left and top cannot
    // accept non-numbers. In production, the coordinates always appear to be numbers...
    // If the coordinates are ever rendered as 0, 0, something has gone wrong.

    if (selectedState.link_counties && tooltipVisible) {
      return (
        <div
          className={'tooltip'}
          style={{ left: coordinates.x || 0, top: coordinates.top || 0 }}
          onMouseEnter={() => handleTouchOrMouseEnter('tooltip')}
        >
          <p className={'tooltipHeading'}>{stateName} BallotTrax Counties:</p>
          {getColumnNumber(selectedState.counties) === 1 && (
            <ul className={'countyList'}>
              {getCountyList(selectedState.counties)}
            </ul>
          )}
          {getColumnNumber(selectedState.counties) === 2 && (
            <div className={'multi-columns'}>
              <ul className={'countyList'}>
                {getCountyList(
                  selectedState.counties.slice(
                    0,
                    Math.ceil(selectedState.counties.length / 2),
                  ),
                )}
              </ul>
              <ul className={'countyList'}>
                {getCountyList(
                  selectedState.counties.slice(
                    Math.ceil(selectedState.counties.length / 2),
                  ),
                )}
              </ul>
            </div>
          )}
          {getColumnNumber(selectedState.counties) === 3 && (
            <div className={'multi-columns'}>
              <ul className={'countyList'}>
                {getCountyList(
                  selectedState.counties.slice(
                    0,
                    Math.ceil(selectedState.counties.length / 3),
                  ),
                )}
              </ul>
              <ul className={'countyList'}>
                {getCountyList(
                  selectedState.counties.slice(
                    Math.ceil(selectedState.counties.length / 3),
                    Math.ceil((selectedState.counties.length * 2) / 3),
                  ),
                )}
              </ul>
              <ul className={'countyList'}>
                {getCountyList(
                  selectedState.counties.slice(
                    Math.ceil((selectedState.counties.length * 2) / 3),
                  ),
                )}
              </ul>
            </div>
          )}
        </div>
      );
    }

    if (!selectedState.link_counties && tooltipVisible) {
      return (
        <div
          className={'tooltip'}
          style={{ left: coordinates.x || 0, top: coordinates.top || 0 }}
          onMouseEnter={() => handleTouchOrMouseEnter('tooltip')}
        >
          <p className={'tooltipHeading'}>Visit {stateName} BallotTrax:</p>
          <p>
            <a
              href={selectedState.url || 'https://ballottrax.net/voter/'}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              {selectedState.url || 'https://ballottrax.net/voter/'}
            </a>
          </p>
        </div>
      );
    }
  }
  return null;
};

export default MapTooltip;
