import React from 'react';

interface LoadingProps {
  width?: string;
  height?: string;
  loadingContainerClass?: string;
}

const Loading = ({
  width = '44',
  height = '44',
  loadingContainerClass = '',
}: LoadingProps) => {
  return (
    <div className={loadingContainerClass}>
      <svg width={width} height={height} viewBox={'0 0 24 24'} fill={'#D0D0D0'}>
        <path
          d={
            'M11 5.08V2c-5 .5-9 4.81-9 10s4 9.5 9 10v-3.08c-3-.48-6-3.4-6-6.92s3-6.44 6-6.92zM18.97 11H22c-.47-5-4-8.53-9-9v3.08C16 5.51 18.54 8 18.97 11zM13 18.92V22c5-.47 8.53-4 9-9h-3.03c-.43 3-2.97 5.49-5.97 5.92z'
          }
        />
        <animateTransform
          attributeName={'transform'}
          attributeType={'XML'}
          type={'rotate'}
          from={'0 0 0'}
          to={'360 0 0'}
          dur={'1s'}
          repeatCount={'indefinite'}
        />
      </svg>
    </div>
  );
};

export default Loading;
